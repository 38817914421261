.notication--alert--container {
  .Toastify__toast {
    padding: 0;
    z-index: 2;
    .Toastify__toast-body {
      z-index: 2;
      padding: 0;
    }
  }
  .custom-alert-notification {
    padding: 8px;
    width: 100%;
  }
  .Toastify__close-button {
    position: absolute;
    right: 2px;
    z-index: 1;
  }
}
