@import '~zina-dogi-react/src/dogi';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '@kenshooui/react-multi-select/dist/style.css';

:root {
  // header colors
  --zina-2: #071534;
  --zina-7: #ffbd00;
  --india-2: #1d0041;

  // dashboard
  --melius-success: #00df6c;
  //--melius-primary: #33308f;
  --melius-primary: #164ebb;
  --melius-primary-light: #464efe;
  --melius-danger: #ff0048;
  --melius-warning: #ffac00;
  --melius-purple: #4d008d;
  --melius-light: #f7f7f8;
  --melius-header-table: #3f17be;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  position: static;
  overflow: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

footer {
  .legend {
    width: calc(100% - 3.1rem);
  }
}

// change color username in menu carets
.custom-carets {
  a {
    color: white;
    font-weight: bold;

    span {
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.zina {
  .drawer {
    background: linear-gradient(187deg, rgba(0, 52, 96, 1) 0%, rgba(48, 110, 222, 1) 100%);
  }
  .menu-bar li .menu-item, .menu-bar li .submenu-item {
    border-bottom: 1px solid rgba(255,255,2555,0.2);
  }

  .MainContentContainer {
    .content {
      .maincontent {
        position: relative;
      }
      footer > .menu {
        background-color: transparent;
      }
    }
  }

  .maincontent>.menu {
    background: linear-gradient(187deg, rgba(0, 52, 96, 1) 0%, rgba(48, 110, 222, 1) 100%);
    position: absolute;
    height: calc(100% + 36px);
  }

  .maincontent>.sheet {
    margin-left: 50px;
  }

}

.navbar {
  &.header {
    background: linear-gradient(90deg, rgba(0, 52, 96, 1) 0%, rgba(121, 84, 222, 1) 100%);
  }

  div.navbar-brand.title-header {
    margin-right: 0;
    width: 100%;

    h4 {
      span {
        float: right;
      }
    }
  }
}

.iconWrapper {
  display: none;
}

// logo zina
.header .logo {
  margin-top: -5px;
  margin-left: 16px;
  width: 65px;
}

.emphasis {
  color: var(--zina-2);
}

.melius-active {
  background-color: var(--melius-primary);
  color: #fff;
}

.suspense-points {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Panel content style */
.panel {
  width: 94vw;
  margin-right: auto;
  margin-left: auto;
}

.page-content {
  display: -moz-grid;
  margin-top: 0.5em;
}

/* End panel content style*/

/* Paginator general style */
.paginator {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .btn-pagination {
    border: 0.5px solid #ddd;
    margin-left: 0.5rem;
  }
}

/* End paginator general style */

.subtitle-header {
  font-size: initial;
  margin-top: -0.5rem;
}

.melius-container {
  background-color: var(--melius-light);
  padding: 0.5rem;
  border-radius: 5px;
}

.label-form {
  font-size: 0.7rem;
}

/*.custom-focus:focus,
.custom-focus:focus ~ .custom-file-label {
  border: solid 2px var(--melius-primary-light);
  box-shadow: none;
}*/
.custom-file-label::after {
  top: -1px;
  height: calc(1.5em + 0.9rem);
}

/* Customizing scroll */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #767677;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: #334;
}

/* End customizing scroll */
.custom-modal-melius {
  .table-header {
    background-color: var(--melius-primary);
    color: #fff;
  }

  .modal-dialog {
    max-width: 50%;
  }

  .header-custom-modal-melius {
    background-color: var(--zina-2);
    color: #fff;
  }

  .modal-title {
    height: 5rem;
    display: inline-block;
    margin: 1rem 0 0 auto;

    span:first-child {
      background-color: var(--melius-primary);
      font-size: 3rem;
      padding: 1rem 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }

  .close,
  .close:hover {
    color: #fff;
  }
}

.custom-modal-height {
  height: 50vh;
}

/* Custom modal melius */

/* End custom modal Melius */

.btn-save,
.btn-save:hover,
.btn-cancel,
.btn-cancel:hover {
  color: #fff !important;
  text-align: center !important;
  width: 8rem im !important;
}

.btn-save {
  background-color: var(--melius-success) !important;
}

button.btn-upload-file {
  background-color: var(--melius-success);
  color: white;
  align-self: flex-end;
  width: 8rem;
}

.btn-cancel {
  background-color: var(--melius-primary) !important;
  margin-right: 1rem !important;
}

.table-header {
  background-color: var(--melius-primary);
  color: #fff;
}

.melius-collapse {
  background-color: #d1d8f2;
  color: var(--melius-primary);
  border: 1px solid var(--melius-primary);
  width: 100%;
  border-radius: 4px;

  .collapse.show {
    background-color: #1d0041;
  }
}

.drawer.container {
  padding-left: 0;
  padding-right: 0;
}

.swal-button-cancel.swal-button--cancel:not([disabled]):hover {
  background-color: #0069d9;
}

//.slick-slide { max-width: 44.2857% }
@media (max-width: 1090px) {
  .hiddenContent {
    display: none;
  }
}

@media (min-width: 1267px) {
  .menu-bar {
    li {

      .submenu-item,
      .menu-item {
        box-sizing: content-box;
      }
    }
  }
}

@media (max-width: 1267px) {
  .menu-bar {
    li {
      .submenu-item {
        span {
          &.item {
            width: 155px;
          }
        }
      }

      .menu-item {
        padding: 12px 0;

        span {
          &.item {
            width: 100px;
          }
        }
      }
    }
  }
}

/*Table*/
.data-grid-container .data-grid {
  table-layout: auto !important;

  .cell {
    height: 100%;
  }
}

.data-grid.custom-sheet {
  width: 100%;
  box-sizing: border-box;

  .data-header>div:after,
  .data-body .data-row:after {
    content: '';
    display: table;
    clear: both;
  }

  .data-header {
    .action-cell.cell {
      height: 35px;
    }

    .cell {
      text-align: center;
      background-color: var(--zina-2);
      color: white;
      padding: 5px 15px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        .custom-select {
          border: none;
          width: auto;
          cursor: pointer;
          padding: 0 1.75rem 0 0.75rem;
          background-color: var(--zina-2);
          border: #201941;
          color: white;
          height: 1.5rem;

          option {
            background-color: white;
            color: black;
          }
        }

        i {
          cursor: pointer;
          margin-left: 3px;
        }
      }
    }
  }

  .data-body {
    .data-row {
      .action-cell {
        &.cell {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--melius-primary);
          color: white;
          height: 34px;

          i {
            cursor: pointer;
          }
        }
      }

      >.cell {
        vertical-align: middle;
        padding: 0px 15px;

        >input.data-editor {
          width: 100%;
          height: 100%;
          border: 2px solid rgb(222, 237, 248);
        }
      }
    }
  }

  .data-header>div>div.action-cell,
  .data-header>tr>.action-cell,
  .data-body .data-row>.action-cell {
    width: 100%;
    display: inline-block;
    height: auto;
    cursor: auto;
  }
}

/*Table*/