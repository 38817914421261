.kpi-card {
  display: inline-flex;
  width: 100%;

  .kpi-header {
    position: relative;
    background-color: var(--melius-primary);
    width: 10rem;
    border-radius: 5px 0 0 5px;
    display: grid;
    place-items: center center;

    .signal {
      display: grid;
      place-items: center center;
      background-color: var(--melius-primary-light);
      width: 5rem;
      height: 70%;
      border-radius: 50%;
      text-align: center;

      .fa-signal {
        font-size: 2rem;
        align-self: center;
      }
    }
  }

  .kpi-body {
    border: 1px solid #33308f;
    padding: 1rem 1.5rem;
    border-radius: 0 5px 5px 0;
    width: 80%;

    .kpi-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .max {
      margin-right: 1rem;
    }
  }
}
