.form-group.extra-menu {
  display: inline-flex;
  margin-top: 0.1rem;
  margin-bottom: 0;

  .last-update {
    color: #fff;
    background-color: #00c346;
    border-radius: 20px;
    padding: 0 0.3rem 0;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 0.8rem;
    align-self: center;

    .icon-reload {
      text-align: center;
      vertical-align: middle;
      margin-bottom: 0.2rem;
    }
  }

  select[name="projects"],
  .searchSelect {
    height: 1.8rem;
    width: 13rem;
    padding: 0;
    border-radius: 2px;
  }
  .select__control {
    min-height: 1.8rem;
    height: 100%;
    & > div {
      height: 100%;
      min-height: 1.8rem;
      height: 100%;
    }
    .select__indicator {
      padding: 0%;
    }
  }
}
