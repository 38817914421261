.CustomDropdownWrapper {
  z-index: 1001;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-sizing: border-box;
  position: absolute;
  transform: translate3d(-136px, 27px, 0px);
  li {
    padding: 0.5rem 1.5rem;
    &:hover {
      background-color: rgba(0, 123, 255, 0.25);
    }
  }
}
.iconWrapper {
  padding: 3px 9px;
  border-radius: 75%;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 123, 255, 0.25);
  }
}
