.custom-line {
  canvas {
    height: 100%;
  }

  .line-actions {
    display: flex;
    justify-content: center;

    .actions {
      margin-right: 1rem;
    }
    .success {
      background-color: var(--melius-success);
      border-color: var(--melius-success);
    }
    .primary {
      background-color: var(--melius-primary);
      border-color: var(--melius-primary);
      margin-right: 0;
    }
  }
}

.grouper .extra-actions {
  border-width: 3px;
  margin-left: 0.5rem;
  color: #fff;
  vertical-align: baseline;
  &:hover {
    color: #fff;
  }
}
