.actions-group {
  background-color: #dce0f0;
  border-radius: 5px;
  padding: 0.3rem;

  .action {
    padding: 0.3rem 2rem;
    color: #333333;
    border: transparent;
  }
}

.action:hover,
.action:focus,
.action:visited,
.action:active {
  border-color: transparent;
  background-color: #fff;
}

span.clicked {
  background-color: #fff;
}

.custom-bar {
  position: relative;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.custom-actions {
  display: inline;
  position: absolute;
  top: 1rem;
  right: 5em;
}

.grouper {
  margin-left: 4rem;

  .extra-actions {
    border-width: 3px;
    margin-left: 0.5rem;
    color: #fff;
  }

  .success {
    background-color: var(--melius-success);
    border-color: var(--melius-success);
  }

  .grouper .primary {
    background-color: var(--melius-primary);
    border-color: var(--melius-primary);
  }
}
